/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.App {
  text-align: center; }

table,
th,
td {
  border: 1px solid black; }

th,
td {
  padding: 15px; }

tr:nth-child(even) {
  background-color: #f2f2f2; }

tfoot,
thead {
  font-weight: bold;
  background-color: #687886;
  color: #fff; }

.viewport {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.5) 100%); }

.insideHeader {
  font-weight: 500;
  font-family: "Great Vibes", cursive;
  font-size: 8vh;
  line-height: 0.7em;
  color: white;
  position: absolute;
  width: 90%; }
  .insideHeader .date {
    font-size: 3vh;
    font-family: "Raleway", sans-serif; }
  .insideHeader.tag {
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .insideHeader.countdown {
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%); }

ul.gallery {
  display: flex;
  flex-wrap: wrap; }
  ul.gallery li {
    height: 15vh;
    flex-grow: 1; }
    @media (max-aspect-ratio: 1 / 1) and (min-height: 1600px) {
      ul.gallery li {
        height: 10vh; } }
  ul.gallery img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom; }
  ul.gallery li:last-child {
    flex-grow: 10; }

.google-maps {
  position: relative;
  padding-bottom: calc(20vh + 15px);
  height: 0;
  overflow: hidden; }
  .google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 20vh !important; }

.grid-container {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: auto auto auto auto;
  padding: 1px;
  margin-bottom: 20px; }

.grid-item {
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 5px;
  text-align: center; }
  .grid-item.header {
    font-weight: bold; }

label {
  cursor: pointer; }

.error {
  color: #e00; }

input[type="text"],
input[type="search"] {
  padding: 10px;
  margin: 10px 10px;
  border-radius: 10px; }

input[type="button"],
input[type="submit"],
input[type="reset"],
.react-add-to-calendar__button {
  /* remove default behavior */
  appearance: none;
  -webkit-appearance: none;
  /* usual styles */
  padding: 10px;
  border: none;
  background-color: #3f51b5;
  color: #fff;
  font-size: 90%;
  border-radius: 5px; }
  input[type="button"]:disabled,
  input[type="submit"]:disabled,
  input[type="reset"]:disabled,
  .react-add-to-calendar__button:disabled {
    background: #dddddd; }

.react-add-to-calendar__dropdown {
  position: absolute;
  top: 25px;
  left: 1px;
  width: 93%;
  padding: 5px 0 5px 8px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
  border: 1px solid #a8a8a8;
  background-color: #fff;
  text-align: left; }

.react-add-to-calendar__dropdown ul li a {
  color: #000;
  text-decoration: none; }

.react-add-to-calendar {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  position: relative;
  display: inline-block;
  margin: 0 auto; }

.eventline {
  margin: 10px;
  border: 1px solid black; }

.rsvpMessageSection {
  margin: 10px; }
  .rsvpMessageSection .textinput {
    width: 100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444; }

body,
input {
  font-family: "Raleway", sans-serif; }

h1,
h2 {
  color: #222;
  margin-left: 0;
  margin-right: 0; }

h1 {
  font-size: 3em;
  margin-top: 0em;
  margin-bottom: 0.5em;
  font-family: "Great Vibes", cursive; }

h2 {
  display: block;
  font-size: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Great Vibes", cursive; }

p {
  margin-bottom: 1em; }

.container {
  padding: 25px 15px;
  background-color: #f8f8f8; }
  .container.alternate {
    background-color: #def; }
